.ant-table-thead {
  text-transform: capitalize;
}
.ant-table-thead > tr > th {
  font-weight: bold !important;
  font-size: 12px;
}

/* color of selected items in multi selection input*/
.ant-select-selection-overflow-item .ant-select-selection-item {
  background: #d6ebdd !important;
  color: var(--ant-color-primary);
}

.ant-menu-item-selected {
  background: #daeade !important;
}

.ant-layout {
  background: #fff !important;
}

.ant-layout-sider-children .ant-menu.ant-menu-root.ant-menu-vertical {
  border-inline-end: none !important;
}

/* specify nested rows*/
.ant-table-row-level-1 {
  background-color: #f5f8f7 !important;
}

.ant-select-selection-item-content {
  color: var(--ant-color-primary) !important;
}


#initial-loading .ant-spin-dot-item {
  background-color: #02862F !important;
}

/* specify modal body */
.ant-modal-confirm-content {
  padding: 15px 0;
}
.ant-modal-confirm .ant-modal-confirm-paragraph {
  max-width: unset !important;
}

@media (max-width: 480px) {
  .ant-picker-dropdown-range {
    width: 320px;
  }
  .ant-picker-datetime-panel,
  .ant-picker-panels {
    flex-direction: column;
  }
}

.ant-drawer-header-title {
  flex-direction: row-reverse;
}
.ant-drawer-body {
  background-color: #f5f8f7;
}

.ant-alert {
  flex-wrap: wrap;
}
.ant-alert-content {
  min-width: 200px !important;
}

.ant-timeline-item-head {
  padding: 0 !important;
}

.border {
  border: 1px solid #e3e6f0 !important;
}

.rounded {
  border-radius: 0.35rem !important;
}

* {
  box-sizing: border-box;
}


.ant-modal  {
  .ant-modal-content {
    padding: 0 !important;
    overflow: hidden;

  }
  .ant-modal-confirm-paragraph{
    gap: 0;
  }
  .ant-modal-confirm-title,.ant-modal-confirm-content,.ant-modal-body,.ant-modal-title {
    padding: 16px 24px;
  }
  .ant-modal-confirm-content,.ant-modal-body {
    background-color: #F5F8F7;
    border: 1px solid #F0F0F0;
    color:#5A5F62;
  }
  .ant-modal-confirm-btns, .ant-modal-footer {
    padding: 10px 16px;
    margin: 0;
  }
  .ant-modal-body > div > div > .anticon{
    display: none;
  }
}

