.top-sidebar-menu {
    overflow: auto;
}

.top-sidebar-menu > .ant-menu-item {
    padding-left: 16px !important;
    padding-inline: 13px;
}

.top-sidebar-menu.collapsed > .ant-menu-item, .top-sidebar-menu.collapsed .ant-menu-submenu-title {
    padding-left: 28px !important;
    padding-inline: 16px;
}

.top-sidebar-menu.collapsed .ant-menu-submenu-title {
    padding-left: 25px !important;
    padding-inline: 16px;
}

.top-sidebar-menu .ant-menu-item-selected,
.top-sidebar-menu .ant-menu-item-active,
.top-sidebar-menu .ant-menu-submenu-selected,
.top-sidebar-menu li.ant-menu-submenu-active,
.bottom-sidebar-menu .ant-menu-item-selected,
.bottom-sidebar-menu .ant-menu-item-active {
    border-radius: 10px !important;
}

.top-sidebar-menu .ant-menu-item, .bottom-sidebar-menu .ant-menu-item {
    width: calc(100% - 8px);
}

.top-sidebar-menu .ant-menu-submenu {
    margin: 0 6px;
}

.top-sidebar-menu .ant-menu-submenu-open {
    border-radius: 10px;
    background-color: #0000000A;
}

.top-sidebar-menu .ant-menu-submenu-title {
    width: 100%;
    padding-left: 14px !important;
    padding-inline: 10px;
    margin-inline: 0 !important;
}

.top-sidebar-menu .ant-menu-submenu-open .ant-menu-sub .ant-menu-item {
    margin-left: 30px;
}

.top-sidebar-menu .ant-menu-submenu-open .ant-menu-sub {
    background-color: transparent !important;
}

.top-sidebar-menu .ant-menu-submenu-selected {
    background-color: rgba(218, 234, 222, 0.6);
}

.top-sidebar-menu .ant-menu-submenu-selected .ant-menu-sub.ant-menu-inline {
    background: none;
}

.ant-menu-submenu-selected .ant-menu-submenu-title {
    font-weight: bolder;
}

.ant-menu-sub .ant-menu-item {
    padding-left: 12px !important;
    margin-left: 36px;
    width: 85%;
    border-radius: 10px;
}

.custom-menu-item .ant-menu-title-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.custom-menu-item .ant-btn {
    border: none;
}

.custom-menu-item .ant-btn-icon {
    color: var(--ant-color-primary) !important;
}

.organization-selector .ant-select-selection-item {
    font-weight: 700;
}

.organization-selector .ant-select-arrow {
    color: black;
}

.ant-menu-item-selected {
    font-weight: bolder;
}

.organization-selector .ant-select-arrow {
    inset-inline-end: 9px !important;
}

.organization-selector .ant-select-selector {
    padding: 0 6px !important;
}

.organization-selector .ant-select-selection-item {
    padding-inline-end: 0 !important;
}

.truncated-select .ant-select-selection-item {
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
}
